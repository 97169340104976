import { Observable } from 'rxjs';

export abstract class IEnvironmentService {
  abstract get current(): Observable<string>;
  abstract getEnvironment(): Observable<string>;
  abstract getDataEnvironment(): Observable<string>;
  abstract change(value: string): void;
  abstract audienceList(env: string): string[];
  abstract isProduction(env: string): boolean;
}
